import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {CallPipe} from "ngxtension/call-apply";
import {isNil} from "lodash";
import {KeyValuePipe, NgClass, NgIf} from "@angular/common";

@Component({
  selector: 'app-validation',
  imports: [CallPipe, NgIf, NgClass, KeyValuePipe],
  templateUrl: './validation.component.html',
  standalone: true
})

export class ValidationComponent {
  @Input() control!: any;
  @Input() submitted: boolean = false;
  @Input() form!: FormGroup;
  @Input() errormessage!: string;
  @Input() name!: string;
  @Input() errorPositionAbsolute: boolean = false;

  displayError(error: any): string {
    if (isNil(error.value)) {
      return '';
    }
    switch (error.key) {
      case 'required' :
      case 'requiredTrue' : {
        return `Field is required`;
      }
      case 'minlength' :
        case 'minLength' : {
        return `Minimum length is ${error.value?.requiredLength} characters`;
      }
      case 'maxlength' :
        case 'maxLength' : {
        return `Maximum length is ${error.value?.requiredLength} characters`;
      }
      case 'min' : {
        return `Minimum value is ${error.value?.min}`;
      }
      case 'max' : {
        return `Maximum value is ${error.value?.max}`;
      }
      case 'email' : {
        return `Please enter a valid email address`;
      }
      case 'maxWeight' : {
        return `${error.value.message}`;
      }
      case 'minWeight' : {
        return `${error.value.message}`;
      }
      case 'pastDate' : {
        return `${error.value.message}`;
      }
      case 'containsSpace' : {
        return `Cannot contain spaces`;
      }
      case 'invalidEmailFormat' : {
        return `Email format is not valid`;
      }
      case 'invalidCharacters' : {
        return `Contains invalid characters`;
      }
      case 'lettersOnly' : {
        return `Only letter are allowed`;
      }
      case 'numbersOnly' : {
        return `Only numbers are allowed`;
      }
      case 'missingSymbolOrNumber' : {
        return `Should contain a symbol or a number`;
      }
      case 'mustBeTrue' : {
        return `Checkbox is required`;
      }
      case 'unique' : {
        return `This value already exists`;
      }
      default :
        return ''
    }
  }
}
