import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import { ClientService } from 'src/shared/services/client/client.service';
import { SelectModel } from '../forms/select/select_model';
import { environment } from 'src/environments/environment';
import { SelectComponent } from '../forms/select/select.component';

@Component({
  selector: 'app-switchregion',
  templateUrl: './switchregion.component.html',
  standalone: true,
  imports: [SelectComponent, ReactiveFormsModule, FormsModule]
})

export class SwitchRegionComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private client: ClientService) { }

  form = this.fb.group({
    region: new FormControl('', [Validators.required]),
  });

  options!:SelectModel[];

  ngOnInit(): void {
    const region = this.client.getRegion();
    this.form.setValue({
      region: region,
    })
    this.populateOptionsFromRegions();
    this.form.get('region')?.valueChanges.subscribe(region => {
      if (!region) return;
      this.onChangeRegion(region);
    });
  }

  populateOptionsFromRegions() {
    this.options = environment.regions.map(region => {
      return {
        value: region.code,
        label: region.name,
        name: region.flag
      }
    });
  }

  onChangeRegion(region: string) {
    this.client.setRegion(region);
    console.log("region", region);
    window.location.href = "/";
  }

}
