<app-rightimagepagewrapper [src]="activeTenant?.pageimage ?? ''">
  <ng-container *ngIf="!emailsent; else linkSent">
  <app-title [title]="'Forgot password'" [description]="'Enter your email address and we\'ll send you an email with instructions on how to create a new password.'" [linktext]="'Back to login'" (linkclick)="login()"></app-title>
  <app-banner *ngIf="error" title="Forgotten Password Error" [description]="error" (dismissclick)="clearError()"></app-banner>
  <form class="form" [formGroup]="form" (ngSubmit)="onSubmitRegister(form)">
    <div class="input-group">
      <app-input
        name="email"
        type="email"
        formControlName="email"
        [span]="6"
        [label]="'generic.email_address' | translate"
        [placeholder]="'Enter email'"
      >
      </app-input>
    </div>
    <app-form-submission-button [form]="form" [buttonText]="'Reset password'"></app-form-submission-button>
  </form>
</ng-container>
  <ng-template #linkSent>
    <app-resetpassword-link-sent [email]="form.value.email ?? ''"></app-resetpassword-link-sent>
  </ng-template>
</app-rightimagepagewrapper>
