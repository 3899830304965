<app-rightimagepagewrapper
  [src]="activeTenant?.pageimage ?? ''">
  @if(!activeTenant?.insurer){
    <app-title [title]="('generic.welcome' | translate)"></app-title>
  }
  @else{
    @if (activeTenant?.name === 'Animal Friends') {
      <app-title [title]="('generic.create_your' | translate) + ' Joii X ' + activeTenant?.name + ' ' + ('generic.insurance_account' | translate)"></app-title>
    } @else {
      <app-title [title]="('generic.create_your' | translate) + ' Joii X ' + activeTenant?.name + ' account'"></app-title>
    }

  }
  <app-banner *ngIf="error" title="Register Error" [description]="error" (dismissclick)="clearError()"></app-banner>
  <form *ngIf="activeTenant?.emailandpassword" class="form" [formGroup]="registerform" (ngSubmit)="onSubmitRegister(registerform)">
    <div class="input-group">
      <app-input name="email" formControlName="email" [label]="'generic.email_address' | translate"
        [placeholder]="('generic.enter_email' | translate)" [span]="6"></app-input>
        @if(activeTenant?.insurer){
        }
      </div>
      @if(activeTenant?.name != 'Joii'){
        <span class="text-xs text-gray-500">*Email address must match the email used for your insurance policy.</span>
      }
    <div>
      <div class="input-group">
        <app-input (valueChange)="onPasswordCheck($event)" name="password" formControlName="password"
          [label]="'generic.password' | translate" type="{{ showPassword ? 'text' : 'password'}}"
          [placeholder]="('generic.enter_password' | translate)" [span]="6">
          <button (click)="togglePasswordVisibility()" type="button" class="absolute inset-y-0 right-0 flex items-center pr-3">
            <ng-icon class="text-2xl" name=" {{ showPassword ? 'ionEyeOutline' : 'ionEyeOff' }}"></ng-icon>
          </button>
        </app-input>
      </div>
      <div>
        <div class="flex justify-between items-center pt-1">
          <div class="flex items-center">
            <ng-icon [ngClass]="{'text-cyan-700': isLengthValid}"
              name="{{ isLengthValid ? 'ionCheckmarkCircle' : 'ionCheckmarkCircleOutline' }}"
              class="text-2xl mr-2"></ng-icon>
            <span class="text-sm">8 Characters minimum</span>
          </div>
        </div>
        <div class="flex items-center">
          <ng-icon [ngClass]="{'text-cyan-700': hasNumberOrSymbol}"
            name="{{ hasNumberOrSymbol ? 'ionCheckmarkCircle' : 'ionCheckmarkCircleOutline' }}"
            class="text-2xl mr-2"></ng-icon>
          <span class="text-sm">Contains a number or symbol</span>
        </div>
      </div>
    </div>
    <app-form-submission-button [form]="registerform" [loading]="loading"
      [buttonText]="'generic.create_account' | translate"></app-form-submission-button>
  </form>
  <button *ngIf="activeTenant?.google" class="google-btn w-full mt-3" (click)="google()">
    <span *ngIf="!loading">Continue With Google</span>
    <span *ngIf="loading">loading...</span>
  </button>
  <div *ngIf="providerswitcher" class="mt-3">
    <app-centertextbutton [text]="'Not with ' + (activeTenant?.name ?? '') + '?'" linktext="Insured with one of our partners"
      (linkclick)="anothertenant()"></app-centertextbutton>
  </div>
  <div class="flex items-center justify-center mt-10">
    <div class="w-full h-[1px] bg-gray-300"></div>
    <div class="mx-4 text-gray-500">Or</div>
    <div class="w-full h-[1px] bg-gray-300"></div>
  </div>
  <div class="mt-10">
    <app-centertextbutton [text]="'Already have an account?'" linktext="Login here"
      (linkclick)="login()"></app-centertextbutton>
  </div>
</app-rightimagepagewrapper>