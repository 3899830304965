import { Routes } from "@angular/router";
import { LoginGuard } from "src/shared/guards/login.guard";

export const AIChatRoutes: Routes = [
    {
      path: 'aichat',
      loadComponent: () => import('./pages/aiChatConfiguration/ai-chat-configuration.component').then(m => m.AIChatConfigurationComponent),
      canActivate: [LoginGuard],
      children: [
        {
          path: '',
          redirectTo: 'context/context',
          pathMatch: 'full'
        },
        {
          path: "context/:type",
          loadComponent: () => import('./pages/aiChatContext/ai-chat-context.component').then(m => m.AIChatContextComponent)
        },
      ]
    },
    {
      path: 'aichat/context/:type/:id',
      loadComponent: () => import('./pages/editContext/edit-context.component').then(m => m.EditContextComponent)
    }
  ];