import { Routes } from "@angular/router";
import { LoginGuard } from "src/shared/guards/login.guard";

export const ShopRoutes: Routes = [
    {
      path: "shop",
      canActivate: [LoginGuard],
      loadComponent: () => import('./pages/shop/shop.component').then(m => m.ShopComponent),
      children: [
          {
          path:'',
          redirectTo: 'products',
          pathMatch: 'full'
          },
        { path: "sub-categories", loadComponent: () => import('./pages/listsubcategories/listsubcategories.component').then(m => m.ListSubCategoriesComponent) },
        { path: "category/:category_id/sub-categories/create", loadComponent: () => import('./pages/createsubcategory/createsubcategory.component').then(m => m.CreateSubCategoryComponent) },
        { path: "category/:category_id/sub-categories/:id", loadComponent: () => import('./pages/getsubcategory/getsubcategory.component').then(m => m.GetSubCategoryComponent) },
        { path: "category/:category_id/sub-categories/:id/update", loadComponent: () => import('./pages/updatesubcategory/updatesubcategory.component').then(m => m.UpdateSubCategoryComponent) },
        { path: "categories", loadComponent: () => import('./pages/listcategories/listcategories.component').then(m => m.ListCategoriesComponent) },
        { path: "categories/create", loadComponent: () => import('./pages/createcategory/createcategory.component').then(m => m.CreateCategoryComponent) },
        { path: "categories/:id", loadComponent: () => import('./pages/getcategory/getcategory.component').then(m => m.GetCategoryComponent) },
        { path: "categories/:id/update", loadComponent: () => import('./pages/updatecategory/updatecategory.component').then(m => m.UpdateCategoryComponent) },
        { path: "categories/:category_id/sub-categories/:id/product-mapper", loadComponent: () => import('./pages/subcategoryproductmapper/subcategoryproductmapper.component').then(m => m.SubcategoryproductmapperComponent) },
        {
          path: "products",
          loadComponent: () => import('./pages/listproducts/listproducts.component').then(m => m.ListProductsComponent)
        },
        {
          path: "products/create",
          loadComponent: () => import('./pages/createproduct/createproduct.component').then(m => m.CreateProductComponent)
        },
        {
          path: "products/:id/update",
          loadComponent: () => import('./pages/updateproduct/updateproduct.component').then(m => m.UpdateProductComponent)
        },
        {
          path: "products/:id",
          loadComponent: () => import('./pages/getproduct/getproduct.component').then(m => m.GetProductComponent)
        },
        {
          path: "products/:id/venomcodes",
          loadComponent: () => import('./pages/venomcodemapper/venomcodemapper.component').then(m => m.VenomCodeMapperComponent)
        },
        {
          path: "products/:id/venomcodes/:venom_code_id",
          loadComponent: () => import('./pages/getvenomcode/getvenomcode.component').then(m => m.GetvenomcodeComponent)
        },
        {
          path: "products/:id/venomcategories/:venom_category_id",
          loadComponent: () => import('./pages/getvenomcategory/getvenomcategory.component').then(m => m.GetvenomcategoryComponent)
        },
        {
          path: "products/:id/venomcategories",
          loadComponent: () => import('./pages/venomcategoriesmapper/venomcategoriesmapper.component').then(m => m.VenomcategoriesmapperComponent)
        },
        {
          path: "products/:id/venomgroups",
          loadComponent: () => import('./pages/venomgroupsmapper/venomgroupsmapper.component').then(m => m.VenomgroupsmapperComponent)
        },
        {
          path: "products/:id/linkCatalogueProduct",
          loadComponent: () => import('./pages/product-to-catalogue-product-mapper/product-to-catalogue-product-mapper.component').then(m => m.ProductToCatalogueProductMapperComponent)
        },
        {
          path: "products/:id/linkSubcategoryProduct",
          loadComponent: () => import('./pages/product-to-subcategories-mapper/product-to-subcategories-mapper.component').then(m => m.ProductToSubcategoryMapperComponent)
        },
        {
          path: "products/:id/venomgroups/:venom_group_id",
          loadComponent: () => import('./pages/getvenomgroup/getvenomgroup.component').then(m => m.GetvenomgroupComponent)
        },
        {
          path: "catalogues",
          loadComponent: () => import('./pages/listcatalogues/listcatalogues.component').then(m => m.ListCataloguesComponent)
        },
        {
          path: "catalogues/create",
          loadComponent: () => import('./pages/createcatalogue/createcatalogue.component').then(m => m.CreateCatalogueComponent)
        },
        {
          path: "catalogues/:id/update",
          loadComponent: () => import('./pages/updatecatalogue/updatecatalogue.component').then(m => m.UpdateCatalogueComponent)
        },
        {
          path: 'catalogues/:id/businesses/update',
          loadComponent: () => import('./pages/updatecataloguebusinesses/updatecataloguebusinesses.component').then(m => m.UpdateCatalogueBusinessesComponent)
        },
        {
          path: 'catalogues/:id/products/:productId/update',
          loadComponent: () => import('./pages/updatecatalogueproduct/updatecatalogueproduct.component').then(m => m.UpdateCatalogueProductComponent)
        },
        {
          path: "catalogues/:id",
          loadComponent: () => import('./pages/getcatalogue/getcatalogue.component').then(m => m.GetCatalogueComponent)
        },
        {
          path: 'catalogues/:id/products/create',
          loadComponent: () => import('./pages/createcatalogueproduct/createcatalogueproduct.component').then(m => m.CreateCatalogueProductComponent)
        },
        {
          path: 'catalogues/:id/products/:productId',
          loadComponent: () => import('./pages/getcatalogueproduct/getcatalogueproduct.component').then(m => m.GetCatalogueProductComponent)
        },
      ]
    }
  ];