import { Component, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-rightimagepagewrapper',
  templateUrl: './rightimagepagewrapper.component.html',
  styles: [`
    :host {
      height: 100%;
    }
  `],
  standalone: true,
})

export class RightImagePageWrapperComponent {
  @Input() src: string = "";



}
