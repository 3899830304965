import { NgIf } from '@angular/common';
import { Component, input, Input } from '@angular/core';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  standalone: true,
  imports: [NgIf]
})

export class LabelComponent {
  @Input() name: string = "";
  @Input() label: string = "";
  showOptional = input(false)
}
