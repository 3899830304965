import { Routes } from "@angular/router";
import { LoginGuard } from "src/shared/guards/login.guard";

export const SecurityRoutes: Routes = [ 
    {
      path: "security",
      canActivate: [LoginGuard],
      loadComponent: () => import('./pages/security/security.component').then(m => m.SecurityComponent),
      children: [
        {
          path:'',
          redirectTo: 'businesses',
          pathMatch: 'full'
          },
        {
          path: "businesses",
          loadComponent: () => import('./pages/listbusinesses/listbusinesses.component').then(m => m.ListBusinessesComponent)
        },
        {
          path: "businesses/create",
          loadComponent: () => import('./pages/createbusiness/createbusiness.component').then(m => m.CreateBusinessComponent)
        },
        {
          path: "businesses/:id",
          loadComponent: () => import('./pages/getbusiness/getbusiness.component').then(m => m.GetBusinessComponent)
        },
        {
          path: "businesses/:id/update",
          loadComponent: () => import('./pages/updatebusiness/updatebusiness.component').then(m => m.UpdateBusinessComponent)
        },
        {
          path: "businesses/:id/delete",
          loadComponent: () => import('./pages/getbusiness/getbusiness.component').then(m => m.GetBusinessComponent)
        },
        {
          path: 'businesses/:id/webhooks/create',
          loadComponent: () => import('./pages/createwebhook/createwebhook.component').then(m => m.CreateWebhookComponent)
        },
        {
          path: 'businesses/:id/webhooks/:webHookId',
          loadComponent: () => import('./pages/getwebhook/getwebhook.component').then(m => m.GetWebHookComponent)
        },
        {
          path: 'businesses/:id/webhooks/:webHookId/update',
          loadComponent: () => import('./pages/updatewebhook/updatewebhook.component').then(m => m.UpdateWebhookComponent)
        },
        {
          path: 'businesses/:id/webhooks/:webHookId/events/update',
          loadComponent: () => import('./pages/updatewebhookevents/updatewebhookevents.component').then(m => m.UpdateWebHookEventsComponent)
        },
        {
          path: 'businesses/:id/apikeys/create',
          loadComponent: () => import('./pages/createapikey/createapikey.component').then(m => m.CreateApiKeyComponent)
        },
        {
          path: 'businesses/:id/apikeys/:keyId',
          loadComponent: () => import('./pages/getapikey/getapikey.component').then(m => m.GetApiKeyComponent)
        },
  
        {
          path: 'businesses/:id/apikeys/:keyId/update',
          loadComponent: () => import('./pages/updateapikey/updateapikey.component').then(m => m.UpdateApiKeyComponent)
        },
  
      ]
    }
  ];