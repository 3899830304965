export interface VetAIHeader {
    hiddenpaths: string[];
    hiddenpagewrappers?: string[];
    accountpath: string;
    logo: string;
    items: VetAIHeaderItem[];
}

export interface VetAIHeaderItem {
    id: string;
    title: string;
    type: VetAIHeaderItemType;
    requiredroles: string[];
    icon: string;
    dataautomationid: string;
    hidden: boolean;
}

export enum VetAIHeaderItemType {
    Logout = 'logout',
    Register = 'register',
    Path = 'path',
    Callback = 'callback',
    Divider = 'divider',
    TokenCopier = 'token-copier',
}