import { Injectable } from '@angular/core';
import { accountinternal, entities } from '../client/client';
import { ClientService } from '../client/client.service';
import { AuthenticationService } from '../auth/auth_service';

@Injectable({
    providedIn: 'root'
})



export class CheckAccessDataService {

    constructor(
        private auth: AuthenticationService,
        private client: ClientService) { }

    private readonly userRolesKey = 'userRoles';

    async GetStaff(): Promise<void> {
        const client = await this.client.getRequestClient();
        return await client.accountinternal.GetMyStaff();
    }

    async CreateStaff(dto: entities.CreateStaffRequest): Promise<void> {
        const client = await this.client.getRequestClient();
        return await client.accountinternal.CreateMyStaff(dto);
    }


    async setRolesData(): Promise<void> {
        const jwt = await this.auth.decodeBearerToken();
        localStorage.setItem(this.userRolesKey, JSON.stringify(jwt.roles));
    }

    getRolesData(): string[] {
        const userAuthDataString = localStorage.getItem(this.userRolesKey);
        return userAuthDataString ? JSON.parse(userAuthDataString) : [];
    }

    hasRole(role: string): boolean {
        const userAuthData = this.getRolesData();
        return userAuthData.includes(role)
    }

}
