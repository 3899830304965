import { Injectable, signal } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class DarkModeService {
    constructor() {
        if (environment.enableDarkmode) {
            window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
                this.darkModeOn.update(() => event.matches)
            });
        }
    }
    darkModeOn = signal(environment.enableDarkmode && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)
    toggleState() {
        this.darkModeOn.update(() => !this.darkModeOn())
    }
}