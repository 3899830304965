import { NgIf } from '@angular/common';
import { Component, Input, input } from '@angular/core';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { BannerComponent } from '../../design/banner/banner.component';
import { TitleComponent } from '../../design/title/title.component';

@Component({
  selector: 'app-resetpassword-link-sent',
  templateUrl: './resetpassword-link-sent.component.html',
  standalone: true,
  imports: [TitleComponent, BannerComponent, NgIf]
})
export class ResetpasswordLinkSentComponent {
  
  constructor(private authenticationService: AuthenticationService) {
    
  }
  @Input() email!: string;
  loading!: boolean;
  error!: string;
  sentAgain: boolean = false;


  resendEmail() {
    this.error = "";
    this.loading = true;
    this.authenticationService.sendPasswordResetEmail(this.email)
    .catch(error => this.handlerError(error.message))
    .then(() => this.next());
  }


  next() {
    this.sentAgain = true;
  }

  handlerError(error: string) {
    this.error = error;
  }
  clearSentAgain(){
    this.sentAgain = false;
  }
  clearError() {
    this.error = "";
  }
}
