<div class="relative">
<div [ngClass]="errorPositionAbsolute ? 'py-1 min-h-8 absolute max-w-full' : ''">
@if(errormessage && errormessage.length > 0 && control && control.errors && (control.touched || control.dirty ||
submitted)) {
<div class="font-light text-xs text-red-600 py-1">
  <span>{{errormessage}}</span>
</div>
}
@else if(control && control.errors && (control.touched || control.dirty || submitted)) {
@for(error of control.errors | keyvalue; track error) {
<div class="font-light text-xs text-red-600 dark:text-red-500 py-1">
  <span>{{error | call:displayError}}</span>
</div>
}
}
</div>
</div>
