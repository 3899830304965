import { Routes } from "@angular/router";
import { LoginGuard } from "src/shared/guards/login.guard";


export const SymptomCheckerRoutes: Routes = [
    {
      path: 'recommendations',
      canActivate: [LoginGuard],
      loadComponent: () => import('src/symptomchecker/pages/sc-recommendations/sc-recommendations.component').then(m => m.SymptomCheckerRecommendationsComponent),
    },
    {
      path: 'symptomchecker',
      canActivate: [LoginGuard],
      loadComponent: () => import('./pages/symptomchecker/symptomchecker.component').then(m => m.SymptomCheckerComponent),
      loadChildren: () => import('./symptomchecker-tab-routes').then(m => m.SymptomCheckerTabRoutes),
    },
  ];