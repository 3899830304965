import { NgIf } from '@angular/common';
import { booleanAttribute, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgIcon, NgIconsModule } from '@ng-icons/core';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  standalone: true,
  imports: [NgIf, NgIconsModule]
})

export class TitleComponent {
  @Input() title: string = "";
  @Input() description!: string;
  @Input() src!: string;
  @Input() linktext: string = "";
  @Input({ transform: booleanAttribute }) createBtn = false;
  @Input({ transform: booleanAttribute }) uploadBtn = false;
  @Input({ transform: booleanAttribute }) backBtn = false;
  @Output() linkclick = new EventEmitter<void>();
  @Output() createclick = new EventEmitter<void>();
  @Output() uploadclick = new EventEmitter<void>();
  @Output() backclick = new EventEmitter<void>();

  linkedClicked() {
    this.linkclick.emit();
  }

  uploadClicked() {
    this.uploadclick.emit();
  }

  createClicked() {
    this.createclick.emit();
  }

  backClicked() {
    this.backclick.emit();
  }
}

