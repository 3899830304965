<span class="sm:col-span-1 sm:col-span-2 sm:col-span-3 sm:col-span-4 sm:col-span-5 sm:col-span-6"></span>
<app-label [label]="label" [showOptional]="showOptional()"></app-label>
<div class="relative rounded-[10px] shadow-sm">
  <input [placeholder]="placeholder || ''" [id]="name" [readonly]="readonly" [disabled]="disabled" [type]="type"
    [attr.data-automationid]="automationID" [ngModel]="value" (ngModelChange)="onInputChange($event)"
    (blur)="onInputBlur($event)" class="joii-input py-4 rounded-[10px]" [ngClass]="{
       'text-gray-900 dark:text-white shadow-sm border border-stone-200 transition-all duration-75 ease-in  placeholder:text-gray-500 focus:outline-none focus:border-blue-900 sm:text-sm sm:leading-6': !readonly,
       'text-gray-500 bg-gray-200 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6 cursor-not-allowed': readonly,
       inputStyleClass: true
    }">
  <ng-content></ng-content>
</div>
<app-validation *ngIf="formControl || errormessage" [control]="formControl" [submitted]="submitted"
  [errormessage]="errormessage" [errorPositionAbsolute]="errorPositionAbsolute"></app-validation>