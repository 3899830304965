import { Routes } from "@angular/router";
import { LoginGuard } from "src/shared/guards/login.guard";

export const UsersRoutes: Routes = [
  {
    path: "users",
    canActivate: [LoginGuard],
    loadComponent: () => import('./pages/users/users.component').then(m => m.UsersComponent),
    children: [
      {
        path: '',
        redirectTo: 'users',
        pathMatch: 'full'
      },
      {
        path: "users",
        loadComponent: () => import('./pages/listusers/listusers.component').then(m => m.ListUsersComponent)
      },
      {
        path: 'behaviour',
        loadComponent: () => import('./pages/user-behaviour/user-behaviour.component').then(m => m.UserBehaviourComponent)
      },
      {
        path: 'practices',
        loadComponent: () => import('./pages/practices/practices.component').then(x => x.PracticesComponent)
      },
      {
        path: 'behaviour/delete-pet-reason/:id',
        loadComponent: () => import('./pages/delete-pet-reason/delete-pet-reason.component').then(m => m.DeletePetReasonComponent)
      },
      {
        path: 'practices/:id',
        loadComponent: () => import('./pages/practice-edit/practice-edit.component').then(x => x.PracticeEditComponent)
      },
      {
        path: ':id',
        loadComponent: () => import('./pages/getuser/getuser.component').then(m => m.GetUserComponent)
      },
      {
        path: ':id/pets/:petId',
        loadComponent: () => import('./pages/getpet/getpet.component').then(m => m.GetPetComponent)
      },
      {
        path: ':id/appointments/:appointmentId',
        loadComponent: () => import('./pages/getappointment/getappointment.component').then(m => m.GetAppointmentComponent)
      },
      {
        path: ':id/appointments/:appointmentId/refunds/:refundId',
        loadComponent: () => import('./pages/getappointmentrefund/getappointmentrefund.component').then(m => m.GetAppointmentRefundComponent)
      }
    ]
  }
];
