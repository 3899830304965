<ul role="list" [ngClass]="class">
  <li data-automationid="tenant" *ngFor="let item of config.items" class="relative">
    <div class="shadow-lg group aspect-h-10 aspect-w-10 flex flex-col justify-between w-full overflow-hidden rounded-lg bg-white focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 transition-all duration-300 ease-in-out hover:bg-cyan-600 hover:bg-opacity-50">
      <div class="grid grid-rows-5">
        <div class="row-span-4 overflow-hidden flex items-center justify-center">
          <img [src]="item.image" alt="" class="object-contain max-w-full max-h-full w-auto h-auto">
        </div>
        <div class="flex items-center justify-center row-span-1">
          <p *ngIf="item.title" class="pointer-events-none block truncate text-neutral-800 text-base font-semibold leading-normal tracking-tight">{{item.title}}</p>
        </div>
        <button (click)="linkedClicked(item)" type="button" class="absolute inset-0 focus:outline-none">
          <span class="sr-only">View details for {{item.title}}</span>
        </button>
      </div>
    </div>
  </li>
</ul>
