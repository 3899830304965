import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  standalone: true,
  imports: [NgIf]
})

export class BannerComponent implements OnInit {
  @Input() type: string = "error"; // error, warning, info, success
  @Input() title: string = "";
  @Input() description: string = "";
  @Input() hidedismiss: boolean = false;
  @Output() dismissclick = new EventEmitter<void>();

  color: string = "red";
  background: string = "bg-red-50";
  textbold: string = "text-red-800";
  textlight: string = "text-red-700";
  button: string = "bg-red-50 text-red-700 hover:bg-red-50 focus:ring-red-600 focus:ring-offset-red-50";

  ngOnInit() {
    if (this.type == "error") {
      this.color = "red";
      this.background = "bg-red-50";
      this.textbold = "text-red-800";
      this.textlight = "text-red-700";
      this.button = "bg-red-50 text-red-700 hover:bg-red-50 focus:ring-red-600 focus:ring-offset-red-50";
    } else if (this.type == "warning") {
      this.color = "yellow";
      this.background = "bg-yellow-50";
      this.textbold = "text-yellow-800";
      this.textlight = "text-yellow-700";
      this.button = "bg-yellow-50 text-yellow-700 hover:bg-yellow-50 focus:ring-yellow-600 focus:ring-offset-yellow-50";
    } else if (this.type == "info") {
      this.color = "blue";
      this.background = "bg-blue-50";
      this.textbold = "text-blue-800";
      this.textlight = "text-blue-700";
      this.button = "bg-blue-50 text-blue-700 hover:bg-blue-50 focus:ring-blue-600 focus:ring-offset-blue-50";
    } else if (this.type == "success") {
      this.color = "green";
      this.background = "bg-green-50";
      this.textbold = "text-green-800";
      this.textlight = "text-green-700";
      this.button = "bg-green-50 text-green-700 hover:bg-green-50 focus:ring-green-600 focus:ring-offset-green-50";
    }
  }

  dismiss() {
    this.dismissclick.emit();
  }

}
