import { Component } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import { Router } from '@angular/router';
import { Tenant } from 'src/environments/evironment.interface';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { ValidationService } from 'src/shared/services/validation/validation.service';
import { TitleComponent } from '../../design/title/title.component';
import { BannerComponent } from '../../design/banner/banner.component';
import { NgIf } from '@angular/common';
import { RightImagePageWrapperComponent } from '../../design/rightimagepagewrapper/rightimagepagewrapper.component';
import { InputComponent } from '../../forms/input/input.component';
import { FormSubmissionButtonComponent } from '../../forms/form-submission-button/form-submission-button.component';
import { ResetpasswordLinkSentComponent } from '../resetpassword-link-sent/resetpassword-link-sent.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-forgottenpassword',
  templateUrl: './forgottenpassword.component.html',
  standalone: true,
  imports: [TitleComponent, BannerComponent, NgIf, RightImagePageWrapperComponent, InputComponent, ReactiveFormsModule, FormSubmissionButtonComponent, ResetpasswordLinkSentComponent, TranslateModule]
})

export class ForgottenPasswordComponent {

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private authenticationService:AuthenticationService) { }

  form = this.fb.group({
    email: new FormControl('', [ValidationService.validateEmail]),
  });
  loading!: boolean;
  error!: string;
  activeTenant:Tenant | undefined = this.authenticationService.getActiveTenant();
  emailsent!: boolean;

  onSubmitRegister(form: FormGroup) {
    if (!form.valid) return;
    if (this.loading) return;
    this.error = "";
    this.loading = true;
    this.authenticationService.sendPasswordResetEmail(form.value.email)
    .then(() => this.next())
    .catch(error => this.handlerError(error.message))
    .finally(() => this.resetForm());
  }

  login() {
    this.router.navigate(['/login']);
  }

  next() {  
    this.emailsent = true;
  }

  handlerError(error: string) {
    this.error = error;
  }

  clearError() {
    this.error = "";
  }

  resetForm() {
    this.loading = false;
  }

}
