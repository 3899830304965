<app-title title="Reset Password Link Sent"></app-title>
<app-banner *ngIf="error" title="Forgotten Password Error" [description]="error" (dismissclick)="clearError()"></app-banner>
<app-banner *ngIf="sentAgain" type="success" title="Email link send successfully" (dismissclick)="clearSentAgain()"></app-banner>
<div class="mt-2 text-sm leading-6 text-gray-500">
    <p>We've sent you an email to reset your password.</p>
    <p>To reset your password, click the link in the email and enter a new one.</p>
</div>
<div class="w-80 h-12 mt-4">
    <button (click)="resendEmail()"
        class="w-full h-full bg-white-900 text-blue-900 border-1 border-gray-400 rounded-3xl text-base font-semibold flex items-center justify-center">
        <span>Resend Email</span>
    </button>
</div>