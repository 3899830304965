import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SquareImageGridConfig, SquareImageGridItem } from './squareimagegrid.interface';
import { NgClass, NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'app-squareimagegrid',
  templateUrl: './squareimagegrid.component.html',
  standalone: true,
  imports: [NgIf, NgClass, NgFor]
})

export class SqaureImageGrid implements OnInit {
  ngOnInit(): void {
    this.class = this.generateGridColumnClasses();
  }
  @Input() config: SquareImageGridConfig = {
    maxcolumns: 4,
    items: [],
  }; 
  class: string = "";
  @Output() linkclick = new EventEmitter<SquareImageGridItem>();
  linkedClicked(item :SquareImageGridItem) {
    this.linkclick.emit(item);
  }
  generateGridColumnClasses(): string {
    const maxColumns = this.config.maxcolumns;
    let gridColumnClasses = `grid grid-cols-2 gap-x-4 gap-y-8`;
  
    if (maxColumns >= 3) {
      gridColumnClasses += ` sm:grid-cols-3 sm:gap-x-6`;
    }
  
    if (maxColumns >= 4) {
      gridColumnClasses += ` lg:grid-cols-4 xl:gap-x-8`;
    }
  
    return gridColumnClasses;
  }
}
