import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-centertextbutton',
  templateUrl: './centertextbutton.component.html',
  standalone: true,
})

export class CenterTextButton {
  @Input() text: string = ""; 
  @Input() linktext: string = ""; 
  @Output() linkclick = new EventEmitter<void>();
  linkedClicked() {
    this.linkclick.emit();
  }
}
