import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-submission-button',
  standalone: true,
  
  imports: [CommonModule],
  templateUrl: './form-submission-button.component.html',
  styleUrl: './form-submission-button.component.scss'
})
export class FormSubmissionButtonComponent {
  @Input() loading!: boolean;
  @Input() small!: boolean;
  @Input() buttonText: string = "";
  @Input() form!: FormGroup;
}
