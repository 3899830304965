import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-pagewrapper',
  templateUrl: './pagewrapper.component.html',
  standalone: true,
  imports: [NgClass, NgTemplateOutlet]
})

export class PageWrapperComponent {
  @Input() setWhitebackground: boolean = true;
  @Input() setBodyWhitebackground: boolean = false;
  @Input() removePadding: boolean = false;
}
