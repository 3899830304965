<div class="flex min-h-full">
  <!-- Content container -->
  <div class="dark:bg-gray-800 flex flex-1 flex-col justify-start px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
    <div class="mx-auto w-full max-w-sm lg:w-96">
      <ng-content></ng-content>
    </div>
  </div>
  <!-- Image container -->
  <div class="relative hidden w-0 flex-1 lg:block">
    <!-- Add a wrapper for the image to control its size -->
    <div class="absolute inset-0 overflow-hidden">
      <img class="h-full w-full object-cover" [src]="src" alt="">
    </div>
  </div>
</div>