<app-label [label]="label" [label]="label"></app-label>
<div class="relative">
  <button #Select [id]="name" [name]="name" [disabled]="disabled"
    class="w-full min-w-48 h-[46px] px-4 py-3.5 inline-flex justify-between items-center shadow text-base border border-stone-200 dark:bg-slate-700 transition-all duration-75 ease-in focus:outline-none focus:border-blue-900 focus:shadow focus:ring-1 focus:ring-primary-300 sm:text-sm rounded-[10px] disabled:cursor-auto disabled:bg-gray-200"
    [class.active]="showOptions()" role="combobox" aria-labelledby="select button" aria-haspopup="listbox"
    aria-expanded="false" aria-controls="select-dropdown" type="button" (blur)="blur()"
    (keydown.Enter)="toggleShowOptions($event)" (click)="toggleShowOptions($event)">
    <span #Placeholder class="text-[15px] tracking-tight truncate"
      [class.placeholder]="!(getValueName | apply:value:options)">{{ (getValueName | apply:value:options) ?
      (getValueName | apply:value:options) : placeholder }}</span>
    <lucide-angular class="text-gray-500 text-xs shrink-0 block" [size]="16" name="chevrons-up-down"></lucide-angular>
  </button>

  <div [ngClass]="{'dark':darkModeService.darkModeOn()}" class="custom-select-options" [class.active]="showOptions()"
    [ariaHidden]="!showOptions()" (clickOutside)="closeOptions($event)">
    @for (option of options; track $index) {
    <div class="custom-select-option" [tabIndex]="0" (keydown.Enter)="optionSelected(option.value)"
      (keydown.Space)="optionSelected(option.value)" (click)="optionSelected(option.value)">
      <span class="truncate">{{ option.name }}</span>
      @if(option.value === value) {<lucide-angular class="text-blue-500 shrink-0" [size]="16"
        name="check"></lucide-angular>}
    </div>
    }
  </div>
</div>
@if (formControl || errormessage) {
  <app-validation [control]="formControl" [submitted]="submitted" [errormessage]="errormessage" [name]="name"></app-validation>
}