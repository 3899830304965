<div class="flex flex-col min-h-fill min-w-fill h-full">
  <app-rightimagepagewrapper
    src="https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2FTenants_pageimage.png?alt=media&token=d60764d8-847e-48be-91fe-d72552517ca1">
    <div class="mb-10">
      <div class="text-neutral-800 text-lg font-medium tracking-wide">Select your insurance provider</div>
    </div>
    <app-squareimagegrid (linkclick)="linkClicked($event)" [config]="gridConfig"></app-squareimagegrid>
    <div
      class="mt-4 shadow-2xl group h-16 w-full overflow-hidden rounded-lg bg-white focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 transition-all duration-300 ease-in-out hover:bg-cyan-600 hover:bg-opacity-50">
      <button (click)="defaultTenantClicked()" type="button" class="w-full h-full focus:outline-none">
        <div class="flex items-center justify-center row-span-1">
          <p class="pointer-events-none block truncate text-sm font-bold text-gray-900">None of the above</p>
        </div>
      </button>
    </div>
  </app-rightimagepagewrapper>
</div>