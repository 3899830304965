import { Routes } from "@angular/router";
import { LoginGuard } from "src/shared/guards/login.guard";

export const AccountRoutes: Routes = [
    {
      path: 'account',
      loadComponent: () => import('./pages/account/account.component').then(m => m.AccountComponent),
      canActivate: [LoginGuard],
      children: [
        {
          path: 'logout',
          loadComponent: () => import('./pages/logoutpage/logoutpage.component').then(m => m.UserLogoutComponent)
        }
      ]
    }
  ];