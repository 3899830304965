import { Routes } from "@angular/router";
import { LoginGuard } from "src/shared/guards/login.guard";

export const MaintenanceRoutes: Routes = [
    {
        path: "maintenance",
        canActivate: [LoginGuard],
        loadComponent: () => import('./pages/maintenance/maintenance.component').then(m => m.MaintenanceComponent),
        children: [
            {
                path: '',
                redirectTo: 'cronjobs',
                pathMatch: 'full'
            },
            {
                path: "cronjobs",
                loadComponent: () => import('./pages/listcronjobs/listcronjobs.component').then(m => m.ListCronJobsComponent)
            },
        ]
    },
];