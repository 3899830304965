import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';

@Component({
  selector: 'app-notauthorised',
  templateUrl: './notauthorised.component.html',
  standalone: true
})

export class NotAuthorisedComponent {

  constructor(
    private router:Router,
    private authentication:AuthenticationService) { }


  logout(){
    this.authentication.logout();
    this.router.navigate(['/login']);
  }

}

