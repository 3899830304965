import { APP_INITIALIZER, ErrorHandler, importProvidersFrom, NgModule } from '@angular/core';
import { PortalComponent } from './portal.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { LucideAngularModule } from 'lucide-angular';
import { ICONS } from 'src/icons';
import { NGICONS } from 'src/icons';
import { NgIconsModule } from '@ng-icons/core';
import { HttpClient, provideHttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { HttpLoaderFactory } from 'src/shared/translate/custom.translate.loader';
import { provideRouter, RouterModule } from '@angular/router';
import { PortalRoutes } from './portal-routes';
import { VetAIHeaderComponent } from 'src/shared/components/design/vetai-header/vetai-header.component';
import { SessionStateService } from 'src/symptomchecker/services/symptomcheckersession/session-state.service';
import { provideAnalytics, getAnalytics } from '@angular/fire/analytics';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideAuth, getAuth, Auth } from '@angular/fire/auth';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { environment } from 'src/environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import { DEFAULT_DIALOG_CONFIG } from '@angular/cdk/dialog';
import { DefaultDialogConfig } from 'src/shared/config/dialog.config';
import { provideRemoteConfig, getRemoteConfig } from '@angular/fire/remote-config';

@NgModule({
  declarations: [
    PortalComponent
  ],
  imports: [
    BrowserAnimationsModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage()),
    provideRemoteConfig(() => getRemoteConfig()),
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
    }),
    LucideAngularModule.pick(ICONS),
    NgIconsModule.withIcons(NGICONS),
    RouterModule,
    VetAIHeaderComponent
  ],
  providers: [
    provideHttpClient(),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    // This ensures auth is ready to rock and roll before any component is created
    {
      provide: APP_INITIALIZER,
      useFactory: (auth: Auth) => () => new Promise((resolve) => {
        auth.onAuthStateChanged((user) => {
          // Firebase is ready, regardless of user being logged in or not
          resolve(true);
        });
      }),
      deps: [Auth],
      multi: true,
    },
    importProvidersFrom(TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })),
    provideRouter(PortalRoutes),
    // Dialog Config
    { provide: DEFAULT_DIALOG_CONFIG, useValue: DefaultDialogConfig },
    SessionStateService
  ],
  bootstrap: [PortalComponent]
})
export class PortalModule { 
  constructor(private translate: TranslateService) {
    translate.setDefaultLang('en');
    const browserLang = translate.getBrowserLang();
    if (browserLang && browserLang.match(/en|de|es/)) {
      translate.use(browserLang);
    } else {
      translate.use('en');
    }
    const savedLang = localStorage.getItem('lang');
    if (savedLang) {
      translate.use(savedLang);
    }
  }
}



