import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Tenant } from 'src/environments/evironment.interface';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { ValidationService } from 'src/shared/services/validation/validation.service';
import { CenterTextButton } from '../../design/centertextbutton/centertextbutton.component';
import { NgClass, NgIf } from '@angular/common';
import { NgIconsModule } from '@ng-icons/core';
import { TranslateModule } from '@ngx-translate/core';
import { BannerComponent } from '../../design/banner/banner.component';
import { RightImagePageWrapperComponent } from '../../design/rightimagepagewrapper/rightimagepagewrapper.component';
import { TitleComponent } from '../../design/title/title.component';
import { FormSubmissionButtonComponent } from '../../forms/form-submission-button/form-submission-button.component';
import { InputComponent } from '../../forms/input/input.component';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  standalone: true,
  imports: [NgIf, NgClass, TitleComponent, FormsModule, ReactiveFormsModule, BannerComponent, InputComponent, TranslateModule, CenterTextButton, FormSubmissionButtonComponent, CenterTextButton, NgIconsModule, RightImagePageWrapperComponent]
})

export class RegisterComponent {

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private validationService: ValidationService) { }

  registerform = this.fb.group({
    email: ['', [ValidationService.validateEmail]],
    password: ['', [ValidationService.validatePassword]]
  });
  loading!: boolean;
  error!: string;
  activeTenant: Tenant | undefined = this.authenticationService.getActiveTenant();
  providerswitcher: boolean = environment.providerswitcher;
  showPassword: boolean = false;
  isLengthValid: boolean = false;
  hasNumberOrSymbol: boolean = false;

  onSubmitRegister(form: FormGroup) {
    if (!form.valid) return;
    if (this.loading) return;
    this.error = "";
    this.loading = true;
    this.authenticationService.createAccount(form.value.email, form.value.password)
      .then(() => this.next())
      .catch(error => this.handlerError(error.message))
      .finally(() => this.resetForm());
  }

  login() {
    this.router.navigate(['/login']);
  }

  anothertenant() {
    this.router.navigate(['/tenants/register']);
  }

  next() {
    this.router.navigate([environment.afterAuthPath]);
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  handlerError(error: string) {
    this.error = error;
  }

  clearError() {
    this.error = "";
  }

  resetForm() {
    this.loading = false;
  }

  onPasswordCheck(password: string): void {
    this.isLengthValid = password.length >= 8;
    this.hasNumberOrSymbol = /[0-9!@#$%^&*()_+]/.test(password);
  }

  google() {
    this.authenticationService.loginWithGoogle()
      .then(() => this.next())
      .catch(error => this.handlerError(error.message))
      .finally(() => this.resetForm());
  }
}
