<div>
  <img *ngIf="src" class="h-10 w-auto mb-8" [src]="src" alt="Logo" data-automationid="title-logo">
  <h2 class="flex items-center text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-white"
    data-automationid="title-heading">
    @if(backBtn) {
    <button (click)="backClicked()" class="py-1 px-2 mt-1" type="button"><ng-icon name="ionArrowBackOutline"
        size="1.5rem"></ng-icon></button>
    }
    <span>{{title}}</span>
    @if(createBtn) {
    <button (click)="createClicked()" class="joii-btn-primary ms-2 py-1 px-2" type="button"><ng-icon
        name="ionAddOutline" size="1.5rem" data-automationid="create-button"></ng-icon></button>
    }
    @if(uploadBtn) {
    <button (click)="uploadClicked()" class="joii-btn-primary ms-2 py-1 px-2" type="button"><ng-icon
        name="ionDocumentOutline" size="1.5rem" data-automationid="upload-button"></ng-icon></button>
    }
  </h2>
  <p class="mt-2 text-sm leading-6 text-gray-500">
    <span *ngIf="description" data-automationid="title-description-label">{{description}}</span>
    <a *ngIf="linktext" (click)="linkedClicked()" class="font-semibold joii-text-primary cursor-pointer hover:underline"
      data-automationid="title-link">
      {{linktext}}
    </a>
  </p>
</div>