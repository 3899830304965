<app-rightimagepagewrapper
  [src]="'https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2FLogin_pageimage.png?alt=media&token=b27d1a8b-9a2d-427f-8c24-2a51bf5b87de'">
  <app-title [title]="('generic.welcome_back' | translate)"></app-title>
  <app-banner *ngIf="error" title="Login Error" [description]="'The email address or password is incorrect. Please try again!'" (dismissclick)="clearError()"></app-banner>
  <form *ngIf="activeTenant?.emailandpassword" class="form" [formGroup]="form" (ngSubmit)="onSubmit(form)">
    <div class="input-group">
      <app-input name="email" formControlName="email" [label]="'generic.email_address' | translate"
        [placeholder]="('generic.enter_email' | translate)" [span]="6"></app-input>
    </div>
    <div>
      <div class="input-group">
        <app-input name="password" formControlName="password"
          [label]="'generic.password' | translate" type="{{ showPassword ? 'text' : 'password'}}"
          [placeholder]="('generic.enter_password' | translate)" [span]="6">
          <button (click)="togglePasswordVisibility()" type="button" class="absolute inset-y-0 right-0 flex items-center pr-3">
            <ng-icon class="text-2xl" name=" {{ showPassword ? 'ionEyeOutline' : 'ionEyeOff' }}"></ng-icon>
          </button>
        </app-input>
      </div>
      <div>     
          <app-righttextbutton linktext="Forgotten Password?" (linkclick)="forgottenpassword()"></app-righttextbutton>
      </div>
    </div>
    <app-form-submission-button [form]="form"[loading]="loading"
      [buttonText]="'generic.login' | translate"></app-form-submission-button>
  </form>
  <button *ngIf="activeTenant?.google" class="google-btn w-full mt-3" (click)="google()">
    <span *ngIf="!loading">Continue With Google</span>
    <span *ngIf="loading">loading...</span>
  </button>
  <div *ngIf="providerswitcher" class="mt-3">
    <app-centertextbutton [text]="'Not with ' + (activeTenant?.name ?? '') + '?'" linktext="Insured with one of our partners"
      (linkclick)="anothertenant()"></app-centertextbutton>
  </div>
  <div class="flex items-center justify-center mt-10">
    <div class="w-full h-[1px] bg-gray-300"></div>
    <div class="mx-4 text-gray-500">Or</div>
    <div class="w-full h-[1px] bg-gray-300"></div>
  </div>
  <div class="mt-10">
    <app-centertextbutton [text]="'New to Joii?'" linktext="Create an account here"
      (linkclick)="register()"></app-centertextbutton>
  </div>
</app-rightimagepagewrapper>