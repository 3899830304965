import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CheckAccessDataService } from 'src/shared/services/checkaccess.data.service/checkaccess.data.service';
import { HttpService } from 'src/shared/services/http/http_service';
import { TitleComponent } from '../../design/title/title.component';
import { BannerComponent } from '../../design/banner/banner.component';
import { PageWrapperComponent } from '../../design/pagewrapper/pagewrapper.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-checkstaffaccess',
  templateUrl: './checkstaffaccess.component.html',
  standalone: true,
  imports: [PageWrapperComponent, TitleComponent, BannerComponent, NgIf]
})

export class CheckStaffAccessComponent {

  constructor(
    private router: Router,
    private checkAccessDataService: CheckAccessDataService
  ) { }

  needsAnAccount: boolean = false;
  loading: boolean = false;
  error = "";
  hasAccess = false;

  ngOnInit(): void {
    this.checkStaff();
  }

  checkStaff() {
    this.error = "";
    if (this.loading) return;
    this.loading = true;
    this.checkAccessDataService.GetStaff()
      .then(() => this.alreadyHasAccount())
      .catch(error => this.handleStaffCheckError(error))
      .finally(() => this.finally());
  }


  handleStaffCheckError(error: any) {
    if (error?.code === "failed_precondition") {
      this.createStaff()
    } else {
      this.unknownError();
    }
  }

  createStaff() {
    this.checkAccessDataService.CreateStaff({})
      .then(() => this.alreadyHasAccount())
      .catch(error => this.handleStaffCreateError(error))
      .finally(() => this.finally());
  }

  handleStaffCreateError(error: any) {
    this.error = error.message ?? "Unknown staff creation error";

  }

  unknownError() {
    this.error = "An unknown error occured validating your staff account.";
  }

  async alreadyHasAccount() {
    await this.checkAccessDataService.setRolesData()
    this.hasAccess = true;
    this.complete();
  }

  complete() {
    this.router.navigate(['/onboarding']);
  }

  finally() {
    this.loading = false;
  }

  clearError() {
    this.error = "";
  }

}
