import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { VetAIHeader, VetAIHeaderItem, VetAIHeaderItemType } from './vetai-header.interface';
import { CheckAccessDataService } from 'src/shared/services/checkaccess.data.service/checkaccess.data.service';
import { getAuth } from "firebase/auth";
import { DarkModeService } from 'src/shared/services/darkmode/darkmode_service';
import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { NgIconsModule } from '@ng-icons/core';
import { PageWrapperComponent } from '../pagewrapper/pagewrapper.component';
import { SwitchRegionComponent } from '../../switchregion/switchregion.component';


@Component({
  selector: 'app-vetai-header',
  templateUrl: './vetai-header.component.html',
  styleUrls: ['./vetai-header.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, NgIconsModule, PageWrapperComponent, SwitchRegionComponent, NgTemplateOutlet]
})
export class VetAIHeaderComponent implements OnInit, AfterViewInit {

  @Input() config: VetAIHeader = {
    hiddenpaths: [],
    accountpath: '',
    items: [],
    logo: ''
  };
  @Input()
  setWhitebackground: boolean = false;
  @Input() showSubMenu?: boolean = true;
  show: boolean = false;
  currentPath: string = '';
  accountname: string = '';
  showmenu: boolean = false;
  pagewrapper: boolean = false;
  profilephoto = "https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2Fvet.webp?alt=media&token=9743f5b9-4824-4fae-ace0-a347c51fbeea"
  @Output() clickedmenuitem = new EventEmitter<VetAIHeaderItem>();
  darkModeService: DarkModeService = inject(DarkModeService)

  constructor(
    private authenticationService: AuthenticationService,
    private checkAccessDataService: CheckAccessDataService,
    private router: Router) { }


  ngOnInit(): void {

    this.show = !this.isPathHidden(this.router.url);
    this.pagewrapper = !this.config.hiddenpagewrappers?.some(wrapper => this.router.url.includes(wrapper));
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.showmenu = false;
        this.currentPath = event.url;
      } else if (event instanceof NavigationEnd) {
        this.show = !this.isPathHidden(event.url);
        this.pagewrapper = !this.config.hiddenpagewrappers?.some(wrapper => this.router.url.includes(wrapper));
        this.currentPath = event.url;
      }
    });
    this.getSetJWTProfilePic()
  }

  ngAfterViewInit(): void {
    this.currentPath = this.router.url;
  }

  async getSetJWTProfilePic() {
    if (this.authenticationService.isLoggedIn()) {
      const jwt = await this.authenticationService.decodeBearerToken();
      this.profilephoto = jwt?.picture ?? this.profilephoto;
      this.accountname = jwt?.name ?? this.accountname;
    }
  }

  toggle() {
    this.showmenu = !this.showmenu;
  }

  isPathHidden(path: string): boolean {
    const hiddenPaths = this.config.hiddenpaths;

    // Check for an exact match
    if (hiddenPaths.includes(path)) {
      return true;
    }

    // Check for fuzzy match (substring)
    for (const hiddenPath of hiddenPaths) {
      if (path.includes(hiddenPath) || hiddenPath.includes(path)) {
        return true;
      }
    }

    if (window.location.href.includes("notfound")) {
      return true;
    }

    return false;
  }

  accountclicked() {
    this.router.navigate([this.config.accountpath]);
  }

  hasAccess(menuItem: VetAIHeaderItem) {
    let hasAccess = false;
    if (menuItem.requiredroles.length === 0) return true;
    if (menuItem.hidden) return false;
    for (let i = 0; i < menuItem.requiredroles.length; i++) {
      if (this.checkAccessDataService.hasRole(menuItem.requiredroles[i])) {
        hasAccess = true;
        break;
      }
    }
    return hasAccess;
  }

  menuItemClicked(menuItem: VetAIHeaderItem) {
    if (menuItem.type === VetAIHeaderItemType.Logout) {
      this.authenticationService.logout();
      this.router.navigate(['/login']);
    } else if (menuItem.type === VetAIHeaderItemType.Register) {
      this.router.navigate(['/register']);
    } else if (menuItem.type === VetAIHeaderItemType.Callback) {
      this.clickedmenuitem.emit(menuItem);
    } else if (menuItem.type === VetAIHeaderItemType.Path) {
      this.router.navigate([menuItem.id]);
    }
    else if (menuItem.type === VetAIHeaderItemType.TokenCopier) {
      async function getToken() {
        const currentUser = getAuth()?.currentUser;
        let token = "";
        if (currentUser) {
          const idTokenResult = await currentUser.getIdTokenResult(false);
          if (idTokenResult) {
            token = idTokenResult.token;
          }
        }
        try {
          await navigator.clipboard.writeText(JSON.stringify({
            "Authorization": token,
            "Accept-Language": "en",
          }));
        } catch (err) {
          console.error('Failed to copy: ', err);
        }
      }

      getToken();
    };
  }

}
