import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {


  static isNotEmpty(control: AbstractControl): any {
    const value = control.value;
    if (value.trim() === '') {
      return { required: true };
    }
    return null;
  }

  static isValidCharacters(control: AbstractControl): any {
    const value = control.value;
    if (!/^[a-zA-Z0-9@.!#$%&'*+/=?^_`|-\s]*$/.test(value)) {
      return { invalidCharacters: true };
    }
    return null;
  }

  static isEmailFormatValid(control: AbstractControl): any {
    const value = control.value;
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
      return { invalidEmailFormat: true };
    }
    return null;
  }

  static containsSymbolOrNumber(control: AbstractControl): any {
    const value = control.value;
    if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?0-9]/.test(value)) {
      return { missingSymbolOrNumber: true };
    }
    return null;
  }

  static containsSpace(control: AbstractControl): any {
    const value = control.value;
    if (/\s/.test(value)) {
      return { containsSpace: true };
    }
    return null;
  }

  static isLettersOnly(control: AbstractControl): any {
    const value = control.value;
    if (!/^[a-zA-Z]*$/.test(value)) {
      return { lettersOnly: true };
    }
    return null;
  }

  static isValidPetName(control: AbstractControl): any {
    const value = control.value;
    if (!/^[a-zA-Z]+(?: [a-zA-Z]+)*$/.test(value)) {
      return { lettersOnly: true };
    }
    return null;
  }

  static isNumbersOnly(control: AbstractControl): any {
    const value = control.value;
    if (!/^[0-9]*$/.test(value)) {
      return { numbersOnly: true };
    }
    return null;
  }
  static isMinLength(control: AbstractControl, minLength: number): any {
    const value = control.value;
    if (value.length < minLength) {
      return { minLength: { requiredLength: minLength } };
    }
    return null;
  }

  static isMaxLength(control: AbstractControl, maxLength: number): any {
    const value = control.value;
    if (value.length > maxLength) {
      return { maxLength: { requiredLength: maxLength } };
    }
    return null;
  }
  static mustBeTrue(control: AbstractControl): any {
    const value = control.value;
    if (value != true) {
      return { mustBeTrue: true };
    }
    return null;
  };

  static validateEmail(control: AbstractControl): any {
    return ValidationService.isNotEmpty(control) ||
      ValidationService.containsSpace(control) ||
      ValidationService.isValidCharacters(control) ||
      ValidationService.isMaxLength(control, 100) ||
      ValidationService.isEmailFormatValid(control);
  }

  static validatePassword(control: AbstractControl): any {
    return ValidationService.isNotEmpty(control) ||
      ValidationService.containsSpace(control) ||
      ValidationService.isValidCharacters(control) ||
      ValidationService.isMinLength(control, 8) ||
      ValidationService.isMaxLength(control, 100) ||
      ValidationService.containsSymbolOrNumber(control)
  }

  static validateLoginPassword(control: AbstractControl): any {
    return ValidationService.isNotEmpty(control) ||
      ValidationService.containsSpace(control) ||
      ValidationService.isValidCharacters(control) ||
      ValidationService.isMaxLength(control, 100)
  }

  static validateNames(control: AbstractControl): any {
    return ValidationService.isNotEmpty(control) ||
      ValidationService.isValidCharacters(control) ||
      ValidationService.isMinLength(control, 1) ||
      ValidationService.isMaxLength(control, 100)

    //|| ValidationService.isLettersOnly(control)
  }

  static validatePhone(control: AbstractControl): any {
    return ValidationService.isNotEmpty(control) ||
      ValidationService.isValidCharacters(control) ||
      ValidationService.isNumbersOnly(control)||
      ValidationService.isMinLength(control, 5) ||
      ValidationService.isMaxLength(control, 20)
  }

  static validatePostCode(control: AbstractControl): any {
    return ValidationService.isNotEmpty(control) ||
      ValidationService.isValidCharacters(control) ||
      ValidationService.isMinLength(control, 2) ||
      ValidationService.isMaxLength(control, 100)

  }

  static maxWeightValidator(maxValue: number, errorMessage: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value === '') {
        return null;
      }
      if (Validators.required(control) !== null || isNaN(value) || value > maxValue) {
        return { 'maxWeight': { message: errorMessage } };
      }
      return null;
    };
}

static minWeightValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value === '') {
        return null;
      }
      if (Validators.required(control) !== null || isNaN(value) || value < 100) {
        return { 'minWeight': { message: 'Minimum weight for cats and dogs is 0.1kgs/0.22lbs' } };
      }
      return null;
    };
}
}
